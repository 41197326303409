/* Mobile */
.galeria-photos {
    height: 300px;
    width: 95%;
    background-size: cover;
    flex: center;
    justify-content: center;
    margin-top: 100px;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.galeria-border-box {
    padding-top: 30px;
    width: 100%;
    height: calc(100% + 30px);
    transition: all 0.3s ease;
}

.galeria-border-box:hover .galeria-photos {
    opacity: 0.8;
    transform: rotate3d(0.5, 0.5, 0.5, 2deg)
}

/* PC */
.galeria-photos {
    height: 400px;
    width: 400px;
    background-size: cover;
    flex: center;
    justify-content: center;
    margin-top: 100px;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.galeria-border-box {
    padding-top: 30px;
    max-width: 97%;
    height: calc(100% + 30px);
    transition: all 0.3s ease;
}

.galeria-border-box:hover {
}

.galeria-border-box:hover .galeria-photos {
    opacity: 0.8;
    transform: rotate3d(0.5, 0.5, 0.5, 2deg)
}