/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Unbounded:wght@200..900&display=swap&family=Taviraj:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap&family=Karla:ital,wght@0,200..800;1,200..800');

@import url(assets/css/custom-navbar.css);
@import url(assets/css/define-bg.css);
@import url(assets/css/font-style.css);
@import url(assets/css/keyFrames.css);
@import url(assets/css/buttons.css);


@import url(assets/css/main-page.css);
@import url(assets/css/oferta-page.css);
@import url(assets/css/galeria.css);
@import url(assets/css/kontakt.css);
@import url(assets/css/oferta-details.css);
@import url(assets/css/oferta-details-idObjects.css);
@import url(assets/css/footer.css);

html{
    background-color: #f9f9f7;
}

body{
    margin: 0;
}

/* Cookie */
#cookies-message {
    color: white;
    padding: 12px 10px;
    text-align: center;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 100000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    display: none;
}

#accept-cookies-checkbox {
    background-color: #1564bc;
    color: white;
    border: solid 1px #4fa3db;
    transition: all 0.5s;
    padding: 2px 6px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 10px;
    text-decoration: none;
    cursor: pointer;
    margin-top: 5px;
}

#accept-cookies-checkbox:hover {
    background-color: transparent;
    border-color: white;
}