@media only screen and (max-width: 767px) {
    footer a {
        color: #d8d8d8 !important;
        text-decoration: none !important;
        transition: all 0.2s ease;

    }

    footer a:hover {
        color: white !important;
        text-decoration: none !important;
    }

    .footer {
        height: 100%;
        color: #d8d8d8;
        font-size: 16px;
    }

    .footer .row{
        margin-top: 0px !important;
    }

    .footer-blueColor {
        color: #4fa3db;
    }

    .footer-leftSide-information {
        display: flex;
        padding-top: 25px;
        padding-left: 30px;


    }

    .footer-leftSide-left {}

    .footer-companyInformation{
        padding-left: 0px;
    }

    .footer-logo {
        height: 400px;
        width: 400px;
    }

    .footer-leftSide-logo {
        background-image: url('../img/logo-black-3d.png');
        width: auto;
        height: 74px;
        background-size: 90%;
        background-repeat: no-repeat;
        opacity: 0.6;
        margin-bottom: 30px;
    }

    .footer .row {
        padding-top: 27px;
    }

    .footer-border-line {
        padding-bottom: 30px;
        ;
    }

    .footer-socialMedia {
        font-size: 25px;
        height: 10px;
        display: flex;
        margin-top: 35px;
        justify-content: center;
    }

    .footer-socialMediaIcon {
        margin-right: 20px;
        transition: all 0.4s ease;
        opacity: 0.5;
    }

    .footer-socialMediaIcon:hover {
        opacity: 1;
        cursor: pointer;
        color: white;
    }

    .footer-booksy-logo {
        background-image: url(../img/icons/booksy-inc-logo-vectors-footer.svg);
        height: 60px;
        width: 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        bottom: 13px;
        right: 8px;
    }

    .footer-lastRow {
        color: #4fa3db;
        padding-top: 20px !important;
    } 
}






/* PC */
@media only screen and (min-width: 768px) {
    footer a {
        color: #d8d8d8 !important;
        text-decoration: none !important;
        transition: all 0.2s ease;

    }

    footer a:hover {
        color: white !important;
        text-decoration: none !important;
    }

    .footer {
        height: 300px;
        color: #d8d8d8;
        font-size: 16px;
    }

    .footer-blueColor {
        color: #4fa3db;
    }

    .footer-leftSide-information {
        display: flex;
        padding-top: 25px;
        padding-left: 30px;

    }

    .footer-leftSide-left {}

    .footer-logo {
        height: 400px;
        width: 400px;
    }

    .footer-leftSide-logo {
        background-image: url('../img/logo-black-3d.png');
        width: auto;
        height: 81px;
        background-size: 90%;
        background-repeat: no-repeat;
        opacity: 0.6;
    }

    .footer .row {
        padding-top: 65px;
    }

    .footer-border-line {
        padding-bottom: 45px;
        border-bottom: 1px solid #2489db;
        ;
    }

    .footer-socialMedia {
        font-size: 25px;
        height: 10px;
        display: flex;
        margin-top: 7px;
    }

    .footer-socialMediaIcon {
        margin-right: 20px;
        transition: all 0.4s ease;
        opacity: 0.5;
    }

    .footer-socialMediaIcon:hover {
        opacity: 1;
        cursor: pointer;
        color: white;
    }

    .footer-booksy-logo {
        background-image: url(../img/icons/booksy-inc-logo-vectors-footer.svg);
        height: 60px;
        width: 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        bottom: 13px;
        right: 8px;
    }

    .footer-lastRow {
        color: #4fa3db;
        padding-top: 30px !important;
    }
}