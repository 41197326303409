/* Mobile */
@media only screen and (max-width: 767px) {

    .ofertaDetails .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .ofertaDetails-part1 .col-md-6 {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .ofertaDetails-part1 {
        padding-top: 70px;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .ofertaDetails-part1-leftSide {
        display: inline-flex;
        height: 100%;
        width: 100%;

    }



    p {
        padding: 10px 10px;
    }



    .ofertaDetails-part1-rightSide {
        padding: 23px;
    }


    .part4-leftSide {
        padding-top: 335px;
        padding-bottom: 85px;
    }

    .ofertaDetails-header-photo {
        min-height: 520px;
        width: 50%;
        background-size: cover;
    }

    .ofertaDetails-part2 .row {
        padding-top: 50px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #dlaczego-warto-section {
        margin-top: 100px;
    }

    .ofertaDetails-part3 .row {
        padding-top: 90px;
    }

    .ofertaDetails-cennik-leftColText {
        padding-top: 20px;
    }

    .ofertaDetails-cennik-photo {
        height: 600px;
        width: 100%;
        background-size: cover;
        margin: 0 auto;
        transition: all 0.3s ease;
    }



}










/* PC */
@media only screen and (min-width: 768px) {

    .ofertaDetails .row {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .ofertaDetails-part1-leftSide {
        position: relative;
        display: inline-flex;
        height: 100%;
        width: 100%;
        margin-top: 70px;
    }

    .ofertaDetails-part1-rightSide {
        padding: 120px 22%;
    }

    .ofertaDetails-part3 .row:last-child {
        padding-bottom: 100px;
    }

    .part4-leftSide {
        padding-top: 160px;
    }

    .ofertaDetails-header-photo {
        min-height: 520px;
        width: 50%;
        background-size: cover;
    }



    .ofertaDetails-part2  .row {
        padding-top: 120px;
    }

    .ofertaDetails-part2 .col-md-6 {
        padding-right: 110px;
    }

    .ofertaDetails-part3 .row {
        padding-top: 120px;
    }

    .ofertaDetails-cennik-leftColText {
        padding-right: 150px !important;
        margin-top: 50px !important;
        padding-left: 50px !important;
    }

    .ofertaDetails-cennik-photo {
        height: 600px;
        width: 85%;
        background-size: cover;
        margin: 0 auto;
        transition: all 0.3s ease;
    }


}