@media only screen and (max-width: 767px) {

    .part1 .row {
        margin-top: 40px !important;
    }

    .part1 {
        position: relative;
        margin-top: 100px;
        padding-bottom: 10px;
    }

    .photo-frame-left {
        width: 320px;
        height: 430px;
        padding: 6px;
        border: 1px solid #ccc6be;
        position: relative;
    }

    .photo-frame-left::before {
        content: '';
        display: block;
        position: absolute;
        top: -7px;
        left: 0px;
        right: -7px;
        bottom: -7px;
        border-top: 7px solid #f9f9f7;
        border-bottom: 7px solid #f9f9f7;
        border-right: 7px solid #f9f9f7;
        box-sizing: border-box;
    }

    .photo-frame-right {
        width: 320px;
        height: 430px;
        padding: 6px;
        border: 1px solid #ccc6be;
        position: relative;
    }

    .photo-frame-right::before {
        content: '';
        display: block;
        position: absolute;
        top: -7px;
        left: -7px;
        right: 0px;
        bottom: -7px;
        border-top: 7px solid #f9f9f7;
        border-bottom: 7px solid #f9f9f7;
        border-right: 0px solid #f9f9f7;
        box-sizing: border-box;
    }

    #part1-photo1 {
        background-image: url(../img/photos/001.jpg);
        background-size: cover;
        background-position: top;
        position: relative;
        width: 60vw;
        left: -13px;
        z-index: 10;
        top: 40px;
    }

    #part1-photo2 {
        background-image: url(../img/photos/002.jpg);
        background-size: cover;
        background-position: 50px;
        width: 60vw;
        position: absolute;
        right: 0;
        z-index: 5;
    }

    .part1-textBox p {
        margin-top: 30px;
        padding-left: 20px;
    }

    .part1-textBox p:first-child {
        margin-top: 80px;

    }

    .part1-text1 {
        font-size: 14px;
        text-align: left;
        letter-spacing: 4px;

    }

    .part1-text2 {
        font-size: 35px;
        text-align: left;
        letter-spacing: 3px;
    }

    .part1-text3 {
        font-size: 15px;
        text-align: left;
        letter-spacing: 0.04em;
        line-height: 1.9;
        max-width: 90%;
    }

    .part2-title {
        text-align: center;
    }

    .part2 .row {
        margin-top: 30px !important;
    }

    .part2-title {
        text-align: left;
    }

    .part2-howTo-text {
        color: white;
        min-height: 100px;
        padding: 0px 10px !important;
        text-align: justify;
        font-size: 16px;
        letter-spacing: 0.01em;
        line-height: 1.8;
        position: relative;
        top: 25px;
        margin-bottom: 50px;
        z-index: 5;
    }

    .part2-howTo-bg {
        z-index: 4;
        font-weight: bold;
        font-size: 20px;
        color: gray;
        position: relative;
        top: 0;
    }

    .part3 {
        position: relative;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .part3-titleBox {
        position: relative;
        top: 35px;
        margin-bottom: 60px;
    }

    #part3-photo1 {
        display: none;
    }

    #part3-photo2 {
        background-size: cover;
        background-position: center;
        width: 100vw;
        height: 50vh;
    }

    #part3-photo3 {
        display: none;
    }

    .part3-titleText1 {
        margin-bottom: 5px;
        font-size: 14px;
        text-align: center;
        letter-spacing: 4px;

    }

    .part3-description1 {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0.03em;
        line-height: 1.8;
        padding: 0px 20px !important;
        margin-bottom: 80px;
    }

    .part3-description2 {
        text-align: left;
        font-size: 15px;
        letter-spacing: 0.03em;
        line-height: 1.8;
        padding: 0px 20px !important;
        margin-bottom: 25px;
    }

    .part3-button {
        position: absolute;
        left: 50%;
        margin-top: 20px;
        transform: translate(-50%, -50%);
    }

    .part4 {
        min-height: 500px;
    }

    .part4 .row {
        margin-right: 0px !important;
        margin-top: 0px !important;
        padding-bottom: 25px;
        --bs-gutter-x: none;
    }

    .part4-rightSide {
        position: absolute;
        display: inline-flex;
        height: 100%;
        width: 100%;
    }

    #part4-firstPhoto {
        background-image: url('../img/photos/6.jpg');
        height: 500px;
        width: 50%;
        background-size: cover;
        opacity: 0.78;
        transition: opacity 0.5s ease;
        display: none;
    }

    #part4-secondPhoto {
        background-image: url('../img/photos/007.jpg');
        background-position: center;
        height: 325px;
        width: 100vw;
        background-size: cover;
        opacity: 0.78;
        transition: opacity 0.5s ease;

    }

    #part4-firstPhoto:hover,
    #part4-secondPhoto:hover {
        opacity: 1;
    }

    .part4-leftSide {
        padding: 10% 10%;
    }

    .part4-text1 {
        font-size: 14px;
        text-align: left;
        letter-spacing: 4px;
        color: white;
        max-width: 410px;
    }

    .part4-text2 {
        font-size: 35px;
        text-align: left;
        letter-spacing: 3px;
        color: white;
        max-width: 410px;

    }

    .part4-text3 {
        font-size: 15px;
        text-align: left;
        letter-spacing: 0.04em;
        line-height: 1.9;
        margin-top: 35px;
    }

    .part5 {
        height: 470px;
        margin-top: -40px !important;
    }

    .part5 .row {
        position: relative;
        top: 25px;
        text-align: center;
    }

    .part5-valueBox {
        margin-bottom: 20px;
    }

    .part5-value {
        font-size: 65px;
    }
}






/* PC */
@media only screen and (min-width: 768px) {

    .part1 .row {
        margin-top: 60px !important;
    }

    .part1 {
        position: relative;
        margin-top: 100px;
        padding-bottom: 100px;
    }

    .photo-frame {
        padding: 6px;
        border: 1px solid #ccc6be;
        position: relative;
    }

    .photo-frame-size-2 {
        height: 490px;
        width: 360px;
        padding: 6px;
        border: 1px solid #ccc6be;
        position: relative;
    }

    .photo-frame::before,
    .photo-frame-size-2::before {
        content: '';
        position: absolute;
        top: -7px;
        left: -7px;
        right: -7px;
        bottom: -7px;
        border: 1px solid #c2bbb1;
        box-sizing: border-box;
    }

    #part1-photo1 {
        width: 320px;
        height: 430px;
        background-image: url(../img/photos/001.jpg);
        background-size: cover;
    }

    #part1-photo2 {
        width: 320px;
        height: 430px;
        background-image: url(../img/photos/002.jpg);
        background-size: cover;
        background-position: center;
    }

    .part1-textBox p {
        margin-top: 30px;
        padding-left: 20px;
    }

    .part1-textBox p:first-child {
        margin-top: 40px;

    }

    .part1-text1 {
        font-size: 14px;
        text-align: left;
        letter-spacing: 4px;

    }

    .part1-text2 {
        font-size: 35px;
        text-align: left;
        letter-spacing: 3px;
    }

    .part1-text3 {
        font-size: 15px;
        text-align: left;
        letter-spacing: 0.04em;
        line-height: 1.9;
        max-width: 90%;
    }

    .part2 .row {
        margin-top: 30px !important;
    }

    .part2-title {
        text-align: left;
    }

    .part2-howTo-text {
        color: white;
        min-height: 100px;
        padding: 0px 35px !important;
        text-align: justify;
        font-size: 16px;
        letter-spacing: 0.01em;
        line-height: 1.8;
        max-width: 95%;
        z-index: 5;
        margin-top: 27px;
    }

    .part2-howTo-bg {
        z-index: 4;
        font-weight: bold;
        font-size: 20px;
        color: gray;
        position: relative;
        top: 0;
    }

    .part3 {
        position: relative;
        padding-top: 60px;
        padding-bottom: 150px;
    }

    .part3-titleBox {
        position: relative;
        top: 80px;
        margin-bottom: 120px;
        margin-top: 50px;
    }

    #part3-photo1 {
        background-image: url(../img/photos/003.jpg);
        background-size: cover;
        background-position: right;
        position: relative;
        top: 100px;
        width: 320px;
        height: 430px;
    }

    #part3-photo2 {
        background-size: cover;
        background-position: center;
        width: 320px;
        height: 430px;
    }

    #part3-photo3 {
        background-image: url(../img/photos/004.jpg);
        background-size: cover;
        background-position: center;
        position: relative;
        top: 100px;
        width: 320px;
        height: 430px;
    }

    .part3-titleText1 {
        font-size: 14px;
        text-align: center;
        letter-spacing: 4px;

    }

    .part3-description1 {
        text-align: justify;
        font-size: 16px;
        letter-spacing: 0.01em;
        line-height: 1.8;
        max-width: 95%;
        padding: 0px 50px !important;
        position: relative;
    }

    .part3-description2 {
        text-align: justify;
        font-size: 16px;
        letter-spacing: 0.01em;
        line-height: 1.8;
        max-width: 95%;
        padding: 0px 50px !important;
        position: relative;
    }

    .part3-button {
        position: relative;
        left: 50%;
        margin-top: 40px;
        transform: translate(-50%, -50%);
    }

    .part4 {
        min-height: 520px;
    }

    .part4 .row {
        margin-right: 0px !important
    }

    .part4-rightSide {
        position: relative;
        display: inline-flex;
        height: 100%;
        width: 100%;
    }

    #part4-firstPhoto {
        background-image: url('../img/photos/006.jpg');
        min-height: 520px;
        width: 50%;
        background-size: cover;
        background-position: center;
        opacity: 0.78;
        transition: opacity 0.5s ease;
    }

    #part4-secondPhoto {
        background-image: url('../img/photos/007.jpg');
        min-height: 520px;
        width: 50%;
        background-size: cover;
        background-position: center;
        opacity: 0.78;
        transition: opacity 0.5s ease;
    }

    #part4-firstPhoto:hover,
    #part4-secondPhoto:hover {
        opacity: 1;
    }

    .part4-leftSide {
        padding: 10% 22%;
    }

    .part4-text1 {
        font-size: 14px;
        text-align: left;
        letter-spacing: 4px;
        color: white;
    }

    .part4-text2 {
        font-size: 35px;
        text-align: left;
        letter-spacing: 3px;
        color: white;
    }

    .part4-text3 {
        font-size: 15px;
        text-align: left;
        letter-spacing: 0.04em;
        line-height: 1.9;
    }

    .part5 {
        height: 270px;
    }

    .part5 .row {
        position: relative;
        top: 70px;
        text-align: center;
    }

    .part5-value {
        font-size: 65px;
    }
}