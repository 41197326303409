/* Mobile */
@media only screen and (max-width: 767px) {
    .oferta-first {
        background-color: black;
    }

    .oferta-first-FullSizeImage {
        background-image: url('../img/photos/16.jpg');
        background-size: cover;
        background-position: center;
        height: 60vh;
        width: 100%;
        opacity: 0.5;
        position: relative;
    }

    .oferta-first-textBox {
        color: white;
        font-size: 17px;
        position: absolute;
        top: 320px;
        margin: auto;
        left: 50%;
        translate: -50% -50%;
    }

    .oferta-first-textFirst {
        font-size: 37px;
        text-align: center;
        letter-spacing: 4px;
        font-weight: bold;

    }

    .oferta-first-textSecond {
        font-size: 50px;
        text-align: center;
        letter-spacing: 3px;
    }

    .oferta-second {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .oferta-second .row {
        margin-top: 20px;
        margin-bottom: 70px;
        padding-right: 0 !important;
    }

    .oferta-second-photos {
        height: 300px;
        width: 95%;
        background-size: cover;
        flex: center;
        justify-content: center;
        margin-top: 100px;
        margin: 0 auto;
        transition: all 0.3s ease;
    }

    .ofert-second-box {
        padding-top: 30px;
        width: 100%;
        height: calc(100% + 30px);
        transition: all 0.3s ease;
    }

    #oferta-second-photo1 {
        background-image: url('../img/photos/008.jpg');
        background-position: -21px;
        background-size: cover;
    }

    #oferta-second-photo2 {
        background-image: url('../img/photos/009.jpg');
    }

    #oferta-second-photo3 {
        background-image: url('../img/photos/010.jpg');
        background-position: 0px -80px;
    }

    .oferta-second-titleBox {
        position: relative;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        padding-top: 10px;
    }

    .oferta-second-describeBox {
        text-align: justify;
        margin-bottom: 10px;
        font-size: 16px;
        position: relative;
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 70px;
    }

    .oferta-second-button {
        position: relative;
        margin-bottom: 50px;
        left: 50%;
        translate: -50%;
        margin-top: 10px;
    }

    .cena-box {
        display: flex;
        position: relative;
        top:20px;
        padding-bottom: 120px;
    }

    .cena-box-cenaTitle {
        font-size: 25px;
        font-size: 35px;
        font-weight: 300;
    }

    .cena-box-cenaPrice {
        font-size: 20px;
        margin-left: 15px;
        font-weight: 100;
        font-size: 35px;
    }
}








/* PC */
@media only screen and (min-width: 768px) {
    .oferta-first {
        background-color: black;

    }

    .oferta-first-textBox {
        margin-top: -50px;

    }

    .oferta-first-FullSizeImage {
        background-image: url('../img/photos/16.jpg');
        background-size: 100%;
        background-position: center;
        height: 60vh;
        width: 100%;
        opacity: 0.5;
        position: relative;
    }

    .oferta-first-textBox {
        color: white;
        font-size: 17px;
        position: absolute;
        top: 40%;
        left: 50%;
        translate: -50% -50%;
    }

    .oferta-first-textFirst {
        font-size: 37px;
        text-align: center;
        letter-spacing: 4px;
        font-weight: bold;

    }

    .oferta-first-textSecond {
        font-size: 50px;
        text-align: center;
        letter-spacing: 3px;
    }

    .oferta-second {
        height: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .oferta-second .row {
        margin-top: 20px;
        margin-bottom: 70px;
    }

    .oferta-second-photos {
        height: 300px;
        width: 300px;
        background-size: cover;
        flex: center;
        justify-content: center;
        margin-top: 100px;
        margin: 0 auto;
        transition: all 0.3s ease;
    }

    .ofert-second-box {
        padding-top: 30px;
        max-width: 97%;
        height: calc(100% + 30px);
        transition: all 0.3s ease;
    }

    .ofert-second-box:hover {
        background-color: #f9f9f7;
        box-shadow: #dddddd 1px 1px 7px 2px;
    }

    .ofert-second-box:hover .oferta-second-photos {
        opacity: 0.8;
        transform: rotate3d(0.5, 0.5, 0.5, 2deg)
    }

    #oferta-second-photo1 {
        background-image: url('../img/photos/008.jpg');
        background-position: -21px;
        background-size: cover;
    }

    #oferta-second-photo2 {
        background-image: url('../img/photos/009.jpg');
    }

    #oferta-second-photo3 {
        background-image: url('../img/photos/010.jpg');
        background-position: right;
    }

    .oferta-second-titleBox {
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: bold;
        position: relative;
        left: 30px;
        top: 10px
    }

    .oferta-second-describeBox {
        text-align: justify;
        margin-bottom: 10px;
        font-size: 16px;
        position: relative;
        top: 7px;
        left: 30px;
        padding-right: 70px;
    }

    .oferta-second-button {
        position: relative;
        left: 50%;
        translate: -50%;
        margin-top: 10px;
    }

    .cena-box {
        display: flex;
        position: relative;
        top:50px
    }

    .cena-box-cenaTitle {
        font-size: 25px;
        font-weight: 300;
    }

    .cena-box-cenaPrice {
        font-size: 20px;
        margin-left: 15px;
        margin-top: 5px;
        font-weight: 100;
    }

}