#kontakt-mainPhoto{
    background-image: url('../img/photos/023.jpg');
    background-size: cover;
    background-position: center top;
}

/* Mobile */
@media only screen and (max-width: 767px) {
    .kontakt-mainPhoto {
        height: 50vh;
        width: 95%;
        background-size: cover;
        flex: center;
        justify-content: center;
        margin-top: 100px;
        margin: 0 auto;
        transition: all 0.3s ease;
    }

    .kontakt-part1-leftSide {
        display: inline-flex;
        height: 100%;
        width: 100%;
    }
}

/* PC */
@media only screen and (min-width: 768px) {
    .kontakt-part1 {
        min-height: 80vh;
        padding-top: 70px;
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .kontakt-mainPhoto {
        height: 100%;
        width: 40vw;
        background-size: cover;
        flex: center;
        justify-content: center;
        margin-top: 100px;
        margin: 0 auto;
        transition: all 0.3s ease;
    }

    .kontakt-part1-leftSide {
        position: relative;
        display: inline-flex;
        height: 100%;
        width: 100%;
    }
}