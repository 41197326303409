.button-black {
    background-color: #161616;
    color: #f9f9f7;
    border: none;
    padding: 7px 24px;
    font-size: 14px;
    letter-spacing: 0.03em;
    transition: all 0.3s ease;
    border: 1px solid #161616
}

.button-black:hover {
    background-color: #f9f9f7;
    color: #212529;
}


.button-black-whiteBorder {
    background-color: #161616;
    color: #f9f9f7;
    border: none;
    padding: 7px 24px;
    font-size: 14px;
    letter-spacing: 0.03em;
    transition: all 0.3s ease;
    border: 1px solid white;
}

.button-black-whiteBorder:hover {
    /* background-color: #cfcfcf;
    border: 1px solid #161616 */
    background-color: #f9f9f7;
    color: #212529;
}

.button-white {
    background-color: #f9f9f7;
    color: #212529;
    border: none;
    padding: 7px 24px;
    font-size: 14px;
    letter-spacing: 0.03em;
    transition: all 0.3s ease;
    border: 1px solid #161616;
    transition: all 0.3s ease;
}

.button-white:hover {
    background-color: #161616;
    color: #f9f9f7;

}
