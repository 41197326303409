/* Pakiety Slubne */
#pakietySlubne-header-first-photo {
    background-image: url('../img/photos/011.jpg');
    background-position: center;
}

#pakietySlubne-header-second-photo {
    background-image: url('../img/photos/012.jpg');
    background-position: center;
}

#oferta-pakiety-slubne-cennik-firstPhoto {
    background-image: url('../img/photos/013.jpg');
}

#oferta-pakiety-slubne-cennik-secondPhoto {
    background-image: url('../img/photos/014.jpg');
    background-position: left;
}

#oferta-pakiety-slubne-cennik-thirdPhoto {
    background-image: url('../img/photos/015.jpg');
    background-position: top;
}





/* MakeUp & GlowUp */

#makeUpGlowUp-header-first-photo {
    background-image: url('../img/photos/016.jpg');
    background-position: center;
}

#makeUpGlowUp-header-second-photo {
    background-image: url('../img/photos/017.jpg');
}

#oferta-makeup-glowup-cennik-firstPhoto{
    background-image: url('../img/photos/018.jpg');
}



/* Szkolenia */
#szkolenia-header-first-photo {
    background-image: url('../img/photos/019.jpg');
    background-position: center;
}

#szkolenia-header-second-photo {
    background-image: url('../img/photos/17.jpg');
    background-position: right;

}

#oferta-szkolenia-cennik-firstPhoto {
    background-image: url('../img/photos/020.jpg');
}

#oferta-szkolenia-cennik-secondPhoto{
    background-image: url('../img//photos/021.jpg');
}

#oferta-szkolenia-cennik-thirdPhoto{
    background-image: url('../img//photos/022.jpg');
}