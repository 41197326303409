@media (max-width: 991px) {

    #navbar-logoImage{
        height: 45px;
        width: 170px;
    }

    .navbar-toggler{
        border: none !important;
        margin-right: 5vw;
    }

    .navbar {
        height: 70px;
        background-color: #f9f9f7 !important;
        border-bottom: 1px solid #c5bfb6;
    }

    .navbar-brand img {
        height: 45px;
        padding-left: 5vw;
    }

    .offcanvas{
        max-width: 80% !important;
        background-color: rgb(255, 128, 170, 0.95);;
    }

    .offcanvas-title {
        /* font-family: 'Cinzel'; */
        font-weight: bold;
        color: #db196d;
        font-size: 30px;
        position: relative;
        left:10px;
    }

    .background {
        height: 50px;
        font-1ly: 'Roboto', sans-serif;
        background-color: rgb(250, 246, 250);
        border-bottom: 2px solid #fbeffb;
        -webkit-box-shadow: 0px 4px 35px 0px rgb(249 231 249);
        -moz-box-shadow: 0px 4px 35px 0px rgb(249 231 249);
        box-shadow: 0px 4px 35px 0px rgb(249 231 249);
    }

    .button-details {
        font-size: 16px;
        font-weight: bold;
        font: normal 600 17px / 25px "Mulish", Helvetica, Arial, Verdana, sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: #b94f8f;
        margin-top: 5px;
    }

    .li-button-details {
        float: left;
        padding: 0 30px;
        font-size: 14px;
        border-right: 1px solid #ffdff1;
    }

    .button-active {
        color: #731c56;
    }

    .booksy_logo {
        background-image: url(../img/icons/booksy-inc-logo-vectors.svg);
        height: 60px;
        width: 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .booksy_description {
        left: 19px;
        position: absolute;
        color: #731c56;
        text-transform: uppercase;
        font: normal 600 10px / 11px "Mulish", Helvetica, Arial, Verdana, sans-serif;
    }

    .booksy_wrapper {
        position: fixed;
        bottom: 0;
        width: 125px;
        right: 0;
        transform: translate(-90px);
    }

    .menu-button
    {
        color: #b8508f;
        border: none;
        background: none;
        font-size: 25px;
    }

}













@media (min-width: 992px) {

    #navbar-logoImage{
        height: 45px;
        width: 200px;
    }

    .navbar {
        height: 70px;
        background-color: #f9f9f7 !important;
        border-bottom: 1px solid #c4beb5;
    }

    .navbar-brand img {
        height: 45px;
        padding-left: 50px;
    }

    .navbar-buttons_wrapper {

        margin-right: 50px;
    }
    
    .button-details {
        font-size: 16px;
        font-weight: bold;
        font: normal 600 17px / 25px "Mulish", Helvetica, Arial, Verdana, sans-serif;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: #b94f8f;
        margin-top: 5px;
        transition: border-width 0.6s linear;
        top:10px;
        position: relative;
        display: inline-block;
        letter-spacing: 0.13em;

    }

    .button-details:hover{
    }

    .button-details:after {
        position: absolute;
        left: 50%;
        content: '';
        height: 1px;
        /* background: #db196d; */
        transition: all 0.2s linear;
        width: 0;
        bottom: -1px;  
        transform: translate(-50%);
    }
    
    .button-details:hover:after {
        width: 100%;
    }


    .li-button-details {
        float: left;
        letter-spacing: 0.04em;
        padding: 0 30px;
        font-size: 15px;
        /* border-right: 1px solid #c4beb5; */
        position: relative;
        height: 70px;
    }

    .li-button-details:first-child {
        /* border-left: 1px solid #c4beb5; */
    }

    .button-active {
        color: #731c56 !important;
        border-bottom: 1px solid #ffc1fa;
        font-size: 40px;

    }

    .booksy_logo {
        background-image: url(../../assets/img/icons/booksy-inc-logo-vectors.svg);
        height: 60px;
        width: 120px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .booksy_description {
        left: 19px;
        position: absolute;
        color: #605f55;
        text-transform: uppercase;
        font: normal 600 10px / 11px "Mulish", Helvetica, Arial, Verdana, sans-serif;
    }

    .booksy_wrapper {
        position: relative;
        top: 15px;
        width: 121px;
        margin-left: 25px;
    }

    .menu-button
    {
        display: none;
    }


}