.title-TopicPageBlack{
  font-family: "Taviraj", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  font-size: 3.5rem;
  color: #212529;
}

.title-TopicPageWhite{
  font-family: "Taviraj", serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.2;
  font-size: 3.5rem;
  color: white;
}

.title-firstTextBlack {
  font-size: 14px;
  text-align: left;
  letter-spacing: 4px;
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  color: #212529;
}

.title-SecondTextBlack {
  font-family: "Unbounded", sans-serif;
  font-optical-sizing: none;
  font-weight: 400;
  font-style: normal;
  font-size: 35px;
  text-align: left;
  letter-spacing: 3px;
  color: #212529;
}

.normalTextBlack {
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0.04em;
  line-height: 1.9;
  color: #212529;
}

.normalTextWhite {
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  text-align: left;
  letter-spacing: 0.04em;
  line-height: 1.9;
  color: white;
}




/* Fonts */
.taviraj-thin {
  font-family: "Taviraj", serif;
  font-weight: 100;
  font-style: normal;
}

.taviraj-extralight {
  font-family: "Taviraj", serif;
  font-weight: 200;
  font-style: normal;
}

.taviraj-light {
  font-family: "Taviraj", serif;
  font-weight: 300;
  font-style: normal;
}

.taviraj-regular {
  font-family: "Taviraj", serif;
  font-weight: 400;
  font-style: normal;
}

.taviraj-medium {
  font-family: "Taviraj", serif;
  font-weight: 500;
  font-style: normal;
}

.taviraj-semibold {
  font-family: "Taviraj", serif;
  font-weight: 600;
  font-style: normal;
}

.taviraj-bold {
  font-family: "Taviraj", serif;
  font-weight: 700;
  font-style: normal;
}

.taviraj-extrabold {
  font-family: "Taviraj", serif;
  font-weight: 800;
  font-style: normal;
}

.taviraj-black {
  font-family: "Taviraj", serif;
  font-weight: 900;
  font-style: normal;
}

.taviraj-thin-italic {
  font-family: "Taviraj", serif;
  font-weight: 100;
  font-style: italic;
}

.taviraj-extralight-italic {
  font-family: "Taviraj", serif;
  font-weight: 200;
  font-style: italic;
}

.taviraj-light-italic {
  font-family: "Taviraj", serif;
  font-weight: 300;
  font-style: italic;
}

.taviraj-regular-italic {
  font-family: "Taviraj", serif;
  font-weight: 400;
  font-style: italic;
}

.taviraj-medium-italic {
  font-family: "Taviraj", serif;
  font-weight: 500;
  font-style: italic;
}

.taviraj-semibold-italic {
  font-family: "Taviraj", serif;
  font-weight: 600;
  font-style: italic;
}

.taviraj-bold-italic {
  font-family: "Taviraj", serif;
  font-weight: 700;
  font-style: italic;
}

.taviraj-extrabold-italic {
  font-family: "Taviraj", serif;
  font-weight: 800;
  font-style: italic;
}

.taviraj-black-italic {
  font-family: "Taviraj", serif;
  font-weight: 900;
  font-style: italic;
}

.roboto-flex-200 {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
}

.roboto-flex-300 {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
}

.unbounded-100 {
  font-family: "Unbounded", sans-serif;
  font-optical-sizing: none;
  font-weight: 400;
  font-style: normal;
}

.karla-200 {
  font-family: "Karla", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

/* Text-Align */
.text-align-justify{
  text-align: justify;
}